import React from "react";
import Container from "react-bootstrap/Container";
import Typewriter from "typewriter-effect";

function Home() {
  return (
    <section
      id="home"
      className="s-home target-section"
      data-parallax="scroll"
      data-image-src="images/home-hero.jpg"
    >
      <div className="overlay"></div>
      <div className="shadow-overlay"></div>

      <div className="home-content">
        <div className="row home-content__main">
          <h3>
            Welcome to Be the Ign<span className="yellow-color">i</span>te
          </h3>

          <h1>
            Unlocking Digital Potential,
            <br />
            Unleashing Business Success! <br />
          </h1>
          <h4 className="description">
            Let's build a <span className="yellow-color"><Typewriter
            options={{
              strings: ["better", "greater","brighter"],
              autoStart: true,
              loop: true,
            }}
          /></span> future
            together
          </h4>
          

          <div className="home-content__buttons">
            <a
              href="mailto:info@betheignite.com"
              className="smoothscroll btn btn--stroke"
            >
              Get in touch
            </a>
            {/* <a href="#about" className="smoothscroll btn btn--stroke">
                More About Us
              </a> */}
          </div>
        </div>

        <div className="home-content__scroll">
          <a href="#about" className="scroll-link smoothscroll">
            <span>Scroll Down</span>
          </a>
        </div>

        <div className="home-content__line"></div>
      </div>

      <ul className="home-social">
        <li>
          <a href="https://www.linkedin.com/company/31077720" target="_blank">
            <i className="fa fa-linkedin" aria-hidden="true"></i>
            <span>LinkedIn</span>
          </a>
        </li>
        <li>
          <a href="#0">
            <i className="fa fa-instagram" aria-hidden="true"></i>
            <span>Instagram</span>
          </a>
        </li>
        <li>
          <a href="https://medium.com/@be.the.ignite" target="_blank">
            <i className="fa fa-medium" aria-hidden="true"></i>
            <span>Medium</span>
          </a>
        </li>
        <li>
          <a href="mailto:info@betheignite.com" target="_blank">
            <i className="fa fa-mail-reply" aria-hidden="true"></i>
            <span>Email</span>
          </a>
        </li>
      </ul>
    </section>
  );
}

export default Home;
