import * as React from "react";
import Container from 'react-bootstrap/Container';
import Logo from './BTI_Logo.png'
import Navbar from './Nav.js'

function Header(){
  return (
    <Container>
    <header className="s-header">

        <div className="header-logo">
            <a className="site-logo" href="index.html">
                <img src={Logo} alt="Homepage"/>
            </a>
        </div>

        
        <Navbar/>

        {/* <a className="header-menu-toggle" href="#0">
            <span className="header-menu-text">Menu</span>
            <span className="header-menu-icon"></span>
        </a> */}

    </header>
    </Container>
  );
}

export default Header;