import * as React from "react";

function Nav()
{
    return(
        <nav className="header-nav">

            <a href="#0" className="header-nav__close" title="close"><span>Close</span></a>

            <div className="header-nav__content">
                <h3>Navigation</h3>
                
                <ul className="header-nav__list">
                    <li className="current"><a className="smoothscroll"  href="#home" title="home">Home</a></li>
                    <li><a className="smoothscroll"  href="#about" title="about">About</a></li>
                    <li><a className="smoothscroll"  href="#services" title="services">Services</a></li>
                    <li><a className="smoothscroll"  href="#works" title="works">Works</a></li>
                    <li><a className="smoothscroll"  href="#clients" title="clients">Clients</a></li>
                    <li><a className="smoothscroll"  href="#contact" title="contact">Contact</a></li>
                </ul>
    
                <p>Perspiciatis hic praesentium nesciunt. Et neque a dolorum <a href='#0'>voluptatem</a> porro iusto sequi veritatis libero enim. Iusto id suscipit veritatis neque reprehenderit.</p>
    
                <ul className="header-nav__social">
                    <li>
                        <a href="#"><i className="fa fa-facebook"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="fa fa-twitter"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="fa fa-instagram"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="fa fa-behance"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="fa fa-dribbble"></i></a>
                    </li>
                </ul>

            </div> 

        </nav>  
    );
}
export default Nav;