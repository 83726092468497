import { useForm } from "react-hook-form";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function Form() {
  const form = useRef();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (e) => 
  {
    
    // console.log("Inside OnSubmit Function",JSON.stringify(e));
    emailjs.sendForm("service_j97mb5g", "template_fvq2kr6", form.current, "OYdE8YbFNLNSNltg-").then(
        (result) => {
          alert("Message Sent Successfully");
          // console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  console.log(watch("mail")); // watch input value by passing the name of it

  return (
   
    <div className="subscribe-form">
      <form id="mc-form" ref={form} className="group" onSubmit={handleSubmit(onSubmit)}>
       
        <input
          defaultValue="Enter Email"
          name="EMAIL"
          className="email"
          id="mc-email"
          type="email"
          {...register("mail")}
        />

        {errors.mail && <span>This field is required</span>}

        <input type="submit" name="subscribe" value="Subscribe"/>
        <label htmlFor="mc-email" className="subscribe-message"></label>
      </form>
    </div>
  );
}
