import React, { useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Form from "../Subscribe/Form";
import { Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./footer.css";

function Footer() {
  const today = new Date();
  const year = today.getFullYear();
 
  return (
    <Container>
      <footer>
        <div className="row footer-main">
          <div className="col-six tab-full left footer-desc">
            <div className="footer-logo" ></div>
            As a leading digital agency, we have a proven track record of
            delivering exceptional results for our clients through a combination
            of creative design, innovative technology, and data-driven strategy.
            Our team of experts is dedicated to driving business growth and
            creating measurable ROI for our clients. We are elevating brands
            through cutting-edge design and strategic digital solutions. Driving
            business growth through innovation and customer-centric approach.{" "}
            <br />
            <br />
            <div className="tech-partners">
              {/* <h4> Tech Partners</h4>
              <div style={{ display: "block", width: 300, padding: 2 }}>
                <Row>
                  <Col>
                    <Image
                      src="https://bti-assets.s3.us-west-2.amazonaws.com/images/Tech/aws-logo.png"
                    />
                  </Col>
                  <Col>
                    <Image
                      src="https://bti-assets.s3.us-west-2.amazonaws.com/images/Tech/Azure.png"
                    />
                  </Col>
                  <Col>
                    <Image
                      src="https://bti-assets.s3.us-west-2.amazonaws.com/images/Tech/GoogleCloud.png"
                    />
                  </Col>
                </Row>
              </div> */}
              <div className="site-footer">
                <h4>Locations </h4>
                <div className="site-footer__bottom">
                  <div className="site-footer__office">
                    <h2>
                      <span>AE</span>DUBAI | HQ
                    </h2>
                    <p>
                      {" "}
                       Dubai, UAE.
                      <br />
                    </p>
                  </div>
                  <div className="site-footer__office">
                    <h2>
                      <span>IN</span>CHENNAI{" "}
                    </h2>
                    <p>
                      {" "}
                      TN, INDIA.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-six tab-full right footer-subscribe">
            <h4> Services</h4>
            <ul>
              <li>Design & Creative</li>
              <li>Web Design & Development</li>
              <li>Strategy & Consulting</li>
              <li>Custom Package & Label Design</li>
              <li>Mobile Design & Development</li>
              <li>PPC Management / Google Ads</li>
              <li>PPC for Lead Generation</li>
              <li>Search Engine Optimization (SEO)</li>
              <li>Lead Gen Design & Development</li>
            </ul>
            
            <h4>Get Notified</h4>
            <p>
              Join our tech-savvy list, be the first to know what's new and
              missed. Stay ahead of the game, subscribe now for the latest in
              digital fame.
            </p>

            <Form />
          </div>
        </div>

        <div className="row footer-bottom">
          <div className="col-twelve">
            <div className="copyright">
              <span> Be the Ignite © Copyright- {year} </span>
            </div>

            <div className="go-top">
              <a className="smoothscroll" title="Back to Top" href="#top">
                <i className="icon-arrow-up" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </Container>
  );
}

export default Footer;
